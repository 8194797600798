import React, { useEffect } from "react";
import classes from "./CheckOut.module.css";
import { useSelector } from "react-redux";
import { Row, Col, Button, Select, Form, Input } from "antd";
import { GoTag } from "react-icons/go";
import { useState } from "react";
import Radio from '@mui/material/Radio';
import { Divider, RadioGroup, FormControlLabel, formControlClasses} from "@mui/material";
import emailjs from "@emailjs/browser";
import { Link, useNavigate } from "react-router-dom";
import CheckOutItem from "./CheckOutItem";
import PopupConfirmedModal from "./Popups/PopupConfirmedModal";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const { TextArea } = Input;

const CheckOut = () => {
  const [form] = Form.useForm();
  const productData = useSelector((state) => state.products.cartItems);
  const userInfo = useSelector((state) => state.products.userInfo);
  const [totalAmt, setTotalAmt] = useState(0); 
  const [Subtotal, setSubtotal] = useState(0); 
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const [confirmedmodalopen, setconfirmedmodalopen] = React.useState(false);
  const handleConfirmedOpen = () => setconfirmedmodalopen(true);

  const handleConfirmedClose = () => {
    setconfirmedmodalopen(false);
  };

  useEffect(() => {
    // Map all items in cartItems and set them in the orderList state
    if (productData) {
      const itemList = productData.map(item => `${item.name} - Quantity: ${item.quantity}, Price: ${item.price}`);
      setFormData(prevData => ({
        ...prevData,
        order: itemList.join("\n") // Join the itemList array with newline characters
      }));
    }
  }, [productData]);
  console.log(formData)

  const handleSubmit = () => {
    console.log(formData)
    emailjs.send("service_5iy7etl", "template_gmyx7cd", formData , "fqHBho_bDdruaK-Zx")
      .then(
        (result) => {
          console.log(result.text);
          handleConfirmedOpen();
          setFormData({});
          form.resetFields();
        },)
        .catch((error) => {
          console.log(error.text);
          toast.error(`Error in placing order. Please try again..`, {
            position: "top-right",
            autoClose: 1500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
            theme: "colored",
          });
        }
      );
 
};

const handleChange = (e) => {
  const { name, value } = e.target;
  setFormData({ ...formData, [name]: value });
};


  useEffect(() => {
    let price = 3;
    let subprice = 0;
    productData?.map((item) => {
      price += item?.quantity * item.price;
      subprice += item?.quantity * item.price;
      return price ,subprice;
    });
    setTotalAmt(price.toFixed(2));
    setSubtotal(subprice.toFixed(2));
  }, [productData]);

  return (
    <div className={classes.cart_container}>
      <div className={classes.header}>
        <div className={classes.title}>
          <h1 style={{margin:'.2em 0'}}>Checkout</h1>
          <div className={classes.border} style={{margin:'auto',width:'80%'}}/>
        </div>
      </div>
      <div className={classes.bigContainer}>
        <div className={classes.billingDetails}>
          <div className={classes.container}>
            <h2 className={classes.subtitle}>Billing Details</h2>
              <Form
                layout="vertical"
                form={form}
                onFinish={handleSubmit}
                style={{
                width: '100%',
                margin:'2em auto',
                alignItems: "center",
                textAlign: "center",
                justifyItems: "center",
                }}
      >
        <Form.Item
          name="email"
          rules={[{ required: true, message: 'Please Enter Your Email!' }]}
          style={{border:'.1em solid #000',backgroundColor:'#fff',width:'100%'}}
        >
                <Input
                size="large" 
                name="email"
                placeholder='Email Address'
                value={formData.email}
                      style={{border:'none', height:'3em'}}
                      onChange={handleChange}
                />
        </Form.Item>
        <div className={classes.inputContainer}>
        <Form.Item
          name="firstName"
          rules={[{ required: true, message: 'Please Enter Your First Name!' }]}
          style={{border:'.1em solid #000',backgroundColor:'#fff',width:'100%'}}
        >
                  <Input
                  name="firstName"
                  placeholder='First Name'
                  size="large" 
                  value={formData.firstName}
                        style={{border:'none', height:'3em'}}
                        onChange={handleChange}
                  />
        </Form.Item>
        <Form.Item
          name="lastName"
          rules={[{ required: true, message: 'Please Enter Your Last Name!' }]}
          style={{border:'.1em solid #000',backgroundColor:'#fff',width:'100%'}}
        >
                  <Input
                  name="lastName"
                  placeholder='Last Name'
                  size="large" 
                  value={formData.lastName}
                        style={{border:'none', height:'3em'}}
                        onChange={handleChange}
                  />
        </Form.Item>
        </div>
        <Form.Item
          name="country"
          rules={[{ required: true, message: 'Please Enter Your Country!' }]}
          style={{border:'.1em solid #000',backgroundColor:'#fff',width:'100%'}}
        >
                <Input
                size="large" 
                name="country"
                placeholder='Country / Region'
                value={formData.country}
                      style={{border:'none', height:'3em'}}
                      onChange={handleChange}
                />
        </Form.Item>
        <Form.Item
          name="address"
          rules={[{ required: true, message: 'Please Enter Your Address!' }]}
          style={{border:'.1em solid #000',backgroundColor:'#fff',width:'100%'}}
        >
                  <Input
                  name="address"
                  placeholder='Address'
                  size="large"
                  value={formData.address}
                        style={{border:'none', height:'3em'}}
                        onChange={handleChange}
                  />
        </Form.Item>
        <div className={classes.inputContainer}>
        <Form.Item
          name="city"
          rules={[{ required: true, message: 'Please Enter Your City!' }]}
          style={{border:'.1em solid #000',backgroundColor:'#fff',width:'100%'}}
        >
                  <Input
                  name="city"
                  placeholder='City'
                  size="large" 
                  value={formData.city}
                        style={{border:'none', height:'3em'}}
                        onChange={handleChange}
                  />
        </Form.Item>
        <Form.Item
          name="phone"
          rules={[{ required: true, message: 'Please Enter Your Phone Number!' }]}
          style={{border:'.1em solid #000',backgroundColor:'#fff',width:'100%'}}
        >
                  <Input
                  name="phone"
                  placeholder='Phone Number'
                  size="large" 
                  value={formData.phone}
                        style={{border:'none', height:'3em'}}
                        onChange={handleChange}
                  />
        </Form.Item>
        </div>
      <h2 className={classes.subtitle}>Order Notes</h2>
      
      <Form.Item
          name="notes"
          style={{border:'.1em solid #000',backgroundColor:'#fff',width:'100%'}}
        >
          <Input.TextArea 
          rows={5}
          name="notes"
          size="large" 
          placeholder='Notes about your order, e.g. special notes for delivery'
          value={formData.notes}
                 style={{border:'none',textAlign:'start'}}
                 onChange={handleChange}
          />
        </Form.Item>
      </Form>
          </div>
        </div>
        <div className={classes.total_con}>
          <div className={classes.totalCardCont}>
            <div className={classes.total}>
              <div className={classes.header}>
                <div className={classes.title} style={{margin:'0 auto 0 1em'}}>
                  <h4 style={{margin:'1em 0 .2em 0'}}>Order Summary</h4>
                  <div className={classes.border} style={{marginRight:'auto'}}/>
                </div>
              </div>
              <div className={classes.cardCont}>
                <CheckOutItem />
              </div>
              <Divider style={{background:'#8A8A8A',margin:'1em auto',width:'90%'}}/>
              <div className={classes.totalrows} >
            <p>SUBTOTAL</p>
            <p style={{ textAlign: "end",fontWeight:"600" }}>${Subtotal}</p>
          </div><div className={classes.totalrows}>
            <p>TAXES</p>
            <p style={{ textAlign: "end" ,fontWeight:"600"}}>Free</p>
          </div>
          <div className={classes.totalrows}>
            <p>DELIVERY</p>
            <p style={{ textAlign: "end" ,fontWeight:"600"}}>$ 3.00</p>
          </div>
          <Divider style={{background:'#8A8A8A',margin:'1em auto',width:'90%'}}/>
          <div className={classes.totalrows} style={{marginBottom:'3em' }}>
            <p>TOTAL</p>
            <p style={{ textAlign: "end",fontWeight:"600"}}>${totalAmt}</p>
          </div>
            </div>
            <div>
              <Button
                size="large"
                onClick={() => form.submit()} // Manually trigger form submission
                className={classes.submitBtn}
                style={{
                  border:'2px solid #000',
                  fontWeight:"600",
                  backgroundColor: '#000',
                  color: 'white',
                  height: '3em',
                  width: '90%',
                  marginLeft: '5%',
                  marginBottom:'2em',
                  borderRadius: '0 7em'
                }}
              >
                Place My Order
              </Button>
            </div>
          </div>
        </div>
      </div>
      
      <PopupConfirmedModal
          open={confirmedmodalopen}
          handleClose={handleConfirmedClose}
        />
    </div>
  );
};

export default CheckOut;