import React, { useContext, useState } from "react";
import classes from "./Ashtray.module.css";
import AuthContext from "../../../Common/auth-context";

import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import CreateIcon from "@mui/icons-material/Create";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { Checkbox, FormControlLabel } from "@mui/material";
import AshtrayModal from "./Ashtray/AshtrayModal";



export const items = [

];


const headCells = [
  {
    id: "name",
    label: "Name",
  },
  {
    id: "description",
    label: "Description",
  },
  {
    id: "price",
    label: "Price",
  },
  // {
  //   id: "size",
  //   label: "Size",
  // },
  {
    id: "outOfStock",
    label: "Out Of Stock",
  },
  {
    id: "newarrival",
    label: "New Arrival",
  },
  {
    id: "mostpopular",
    label: "Most Popular",
  },
  {
    id: "edit",
    label: "Edit",
  },
];

function EnhancedTableHead(props) {

  return (
    <TableHead>
      <TableRow className={classes.headingRow}>
        {headCells.map((headCell) => (
          <TableCell className={classes.headingRow} key={headCell.id}><b>
            {headCell.label}</b>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected } = props;
    const authCtx = useContext(AuthContext);

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
      className={classes.tableHeading}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Items
        </Typography>
      )}

      {/* <ItemSearchBox data={items} cat={menu} /> */}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
      {/* <AddCircleIcon onClick={() => {authCtx.setOpenItemModal(true);}} /> */}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const AshtrayTable = () => {
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const authCtx = useContext(AuthContext);
  const [openModule, setopenModule] = useState("Add Image");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleDelete = (id) => {
    console.log(id, "id");
    authCtx.deleteAshtray(id);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - items.length) : 0;

  const handleClose = () => {
    authCtx.setOpenAshtrayModal(false);
  };
  const handleAdd = () => {
    authCtx.seteditmood(false);
    authCtx.setselectedItem({});
    console.log("add image");
    authCtx.setOpenAshtrayModal(true);
    setopenModule("Add Ashtray");
  };

  const handleCheckboxChange = (id, field) => {
    console.log("Checkbox clicked for item ID:", id);
    console.log("Field to be updated:", field);
  
    const updatedItems = authCtx.ashtray.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          [field]: !item[field],
        };
      }
      return item;
    });
  
    console.log("Updated items:", updatedItems);
  
    // Update the document in Firebase with the updated item
    authCtx.updateAshtray(id, updatedItems.find((item) => item.id === id));
  
    // Update the state
    authCtx.setAshtray(updatedItems);
  };  
  

  return (
    <div className={classes.itemsContainer}>
      <div className={classes.itemsHeading}>
        <div className={classes.headContainer}>
          <p className={classes.itemHead}>Ashtray Products</p>
          <p className={classes.subHead}>Manage your Ashtray Products</p>
        </div>
        <AddCircleIcon onClick={handleAdd} className={classes.addbtn} />
      </div>
      <div className={classes.itemsTableContainer}>
        <Paper sx={{ width: "100%", mb: 2 }}>
          {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                rowCount={items.length}
              />
              <TableBody>
                {authCtx?.ashtray
                  .slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                  .map((items, index1) => {
                    return (
                      <TableRow
                        className={classes.itemRow}
                        onDoubleClick={() => {
                          authCtx.seteditmood(true);
                          authCtx.setOpenAshtrayModal(true);
                          setopenModule("Edit Your Information");
                          authCtx.setselectedItem(items)
                        }}
                      >
                        <TableCell align="center">{items.name}</TableCell>
                        <TableCell align="center">{items.description}</TableCell>
                        <TableCell align="center">{items.price}</TableCell>
                        {/* <TableCell align="center">{items.size}</TableCell>
                        <TableCell align="center">{items.color}</TableCell> */}
                        <TableCell align="center">
                          <FormControlLabel
                          style={{ color: 'black' }}
                            control={<Checkbox checked={items.outOfStock === true} onChange={() => handleCheckboxChange(items.id, 'outOfStock')} />}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <FormControlLabel
                          style={{ color: 'black' }}
                            control={<Checkbox checked={items.newarrival === true} onChange={() => handleCheckboxChange(items.id, 'newarrival')} />}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <FormControlLabel classes={{root: classes.checkboxRoot}}
                            control={<Checkbox checked={items.mostpopular === true} onChange={() => handleCheckboxChange(items.id, 'mostpopular')} />}
                          />
                        </TableCell>
                        <TableCell align="right">
                          <div className={classes.iconsCon}>
                             <CreateIcon
                              onClick={() => {
                                authCtx.seteditmood(true);
                                authCtx.setOpenAshtrayModal(true);
                                setopenModule("Edit Your Information");
                                authCtx.setselectedItem(items)
                              }}
                              class={classes.edit}
                            /> 
                            <DeleteForeverIcon
                              class={classes.freeze}
                              onClick={() => {
                                handleDelete(items.id);
                              }}

                            />
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  }
                  )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={authCtx?.ashtray.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
        <AshtrayModal handleClose={handleClose}
          lable={openModule} />
      </div>
    </div>
  );
};
export default AshtrayTable

